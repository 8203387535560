import React from 'react'

export default function AlertError({
  message,
}: {
  message: string | null
}): React.JSX.Element {
  return (
    <>{message ? <div className="alert alert-danger">{message}</div> : null}</>
  )
}

import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../../context/auth/AuthContext'
import Pagination from '../../../components/Pagination'
import usePage from '../../../usePage'
import Moment from 'react-moment'
import useSWR from 'swr'
import fetchApi from '../../../fetchApi'
import AlertError from '../../../components/Alert/AlertError'
import Loading from '../../../components/Loading'

function CorporateActsIndex() {
  const [token] = useContext(AuthContext)
  const page = usePage()

  const { data, isLoading, error } = useSWR(
    { uri: '/edge/admin/corporate/acts?page=' + page, token },
    fetchApi,
  )

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Acts</li>
        </ol>
      </nav>
      <div className="container-fluid">
        {error ? <AlertError message={error.status} /> : null}
        {isLoading ? <Loading /> : null}

        {data ? (
          <div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Number</th>
                  <th>Company</th>
                  <th>Cost</th>
                  <th>Subject</th>
                </tr>
              </thead>
              <tbody>
                {data.items.map((act) => (
                  <tr key={act.id}>
                    <td>
                      <Moment format="YYYY-MM-DD HH:mm:ss">{act.date}</Moment>
                    </td>
                    <td>
                      <a href={act.url} target="_blank" rel="noreferrer">
                        {act.number}
                      </a>
                    </td>
                    <td>
                      <Link to={'/corporate/companies/' + act.company.id}>
                        {act.company.name}
                      </Link>
                    </td>
                    <td>{act.cost}</td>
                    <td>{act.subject}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              page={page}
              totalRows={data.pagination.total}
              perPage={data.pagination.per_page}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default CorporateActsIndex

import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../../context/auth/AuthContext'
import Pagination from '../../../components/Pagination'
import Moment from 'react-moment'
import usePage from '../../../usePage'
import useSWR from 'swr'
import fetchApi from '../../../fetchApi'
import AlertError from '../../../components/Alert/AlertError'
import Loading from '../../../components/Loading'

export default function EduMembersIndex() {
  const [token] = useContext(AuthContext)
  const page = usePage()

  const {
    data: members,
    isLoading,
    error,
  } = useSWR({ uri: '/edge/admin/edu/members?page=' + page, token }, fetchApi)

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Members</li>
        </ol>
      </nav>
      <div className="container-fluid">
        {error ? <AlertError message={error.status} /> : null}
        {isLoading ? <Loading /> : null}
        {members ? (
          <div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {members.items.map((member) => (
                  <tr key={member.id}>
                    <td>
                      <Moment format="YYYY-MM-DD HH:mm:ss">
                        {member.create_date}
                      </Moment>
                    </td>
                    <td>{member.email}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              page={page}
              totalRows={members.pagination.total}
              perPage={members.pagination.per_page}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

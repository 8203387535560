import React, { useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import AuthContext from '../../../context/auth/AuthContext'
import useSWR from 'swr'
import fetchApi from '../../../fetchApi'
import AlertError from '../../../components/Alert/AlertError'
import Loading from '../../../components/Loading'

export default function AuthorsAuthorShow() {
  const { id } = useParams()
  const [token] = useContext(AuthContext)

  const {
    data: author,
    isLoading,
    error,
  } = useSWR({ uri: `/edge/admin/authors/${id}`, token }, fetchApi)

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/authors">Authors</Link>
          </li>
          {author ? (
            <li className="breadcrumb-item active">{author.id}</li>
          ) : null}
        </ol>
      </nav>
      <div className="container-fluid">
        {error ? <AlertError message={error.status} /> : null}
        {isLoading ? <Loading /> : null}
        {author ? (
          <div>
            <p className="controls">
              <Link
                to={'/authors/' + author.id + '/edit'}
                className="btn btn-primary"
              >
                Edit
              </Link>
            </p>
            <div>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <th>ID</th>
                    <td>{author.id}</td>
                  </tr>
                  <tr>
                    <th>Photo</th>
                    <td>
                      <img src={author.photo} alt="" />
                    </td>
                  </tr>
                  <tr>
                    <th>Name</th>
                    <td>
                      {author.name.first} {author.name.last}
                    </td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{author.email}</td>
                  </tr>
                  <tr>
                    <th>Site</th>
                    <td>{author.site}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import AuthContext from '../../../../context/auth/AuthContext'
import handleResponse from '../../../../handleResponse'
import parseErrorMessage from '../../../../parseErrorMessage'
import Moment from 'react-moment'
import parseValidationErrors from '../../../../parseValidationErrors'
import moment from 'moment/moment'

function PaymentOrdersShow() {
  const params = useParams()

  const [token, setToken] = useContext(AuthContext)

  const [order, setOrder] = useState(null)
  const [payments, setPayments] = useState(null)
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)

  const [payExternalFormData, setPayExternalFormData] = useState({
    date: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
    service: '',
    transaction: '',
  })

  const [payExternalErrors, setPayExternalErrors] = useState({})

  useEffect(() => {
    fetch('/api/edge/admin/payment/orders/' + params.id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then((data) => {
        setOrder(data)
        fetch('/api/edge/admin/payment/orders/' + params.id + '/payments', {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
          .then(handleResponse)
          .then((data) => {
            setPayments(data)
          })
      })
      .catch((error) => {
        if (error.status === 401) {
          setToken(null)
        }
        setError(parseErrorMessage(error))
      })
  }, [params, setOrder, setPayments, token, setToken, setError])

  const payExternalHandleChange = (event) => {
    setPayExternalFormData({
      ...payExternalFormData,
      [event.target.name]:
        event.target.type === 'number'
          ? parseFloat(event.target.value)
          : event.target.value,
    })
  }

  const payExternalHandleSubmit = (event) => {
    event.preventDefault()
    setError(null)
    setSuccess(null)
    fetch('/api/edge/admin/payment/orders/' + order.id + '/pay-external', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(payExternalFormData),
    })
      .then(handleResponse)
      .then(() => {
        setSuccess('Success!')
      })
      .catch(async (error) => {
        setPayExternalErrors(await parseValidationErrors(error))
        setError(parseErrorMessage(error))
      })
  }

  const markPreSent = (id) => {
    if (!confirm('Mark Pre Receipt as sent?')) {
      return
    }
    setError(null)
    fetch('/api/edge/admin/payment/payments/' + id + '/mark-pre-sent', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then(() => {
        setSuccess('Pre Receipt is marked as sent.')
      })
      .catch((error) => {
        setError(parseErrorMessage(error))
      })
  }

  const markPostSent = (id) => {
    if (!confirm('Mark Post Receipt as sent?')) {
      return
    }
    setError(null)
    fetch('/api/edge/admin/payment/payments/' + id + '/mark-post-sent', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then(() => {
        setSuccess('Post Receipt is marked as sent.')
      })
      .catch((error) => {
        setError(parseErrorMessage(error))
      })
  }

  const markFullSent = (id) => {
    if (!confirm('Mark Full Receipt as sent?')) {
      return
    }
    setError(null)
    fetch('/api/edge/admin/payment/payments/' + id + '/mark-full-sent', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then(() => {
        setSuccess('Full Receipt is marked as sent.')
      })
      .catch((error) => {
        setError(parseErrorMessage(error))
      })
  }

  const postReceipt = (id) => {
    if (!confirm('Resend Post Receipt?')) {
      return
    }
    setError(null)
    fetch('/api/edge/admin/payment/payments/' + id + '/post-receipt', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then(() => {
        setSuccess('Post Receipt resending is queued.')
      })
      .catch((error) => {
        setError(parseErrorMessage(error))
      })
  }

  const fullReceipt = (id) => {
    if (!confirm('Resend Full Receipt?')) {
      return
    }
    setError(null)
    fetch('/api/edge/admin/payment/payments/' + id + '/full-receipt', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then(() => {
        setSuccess('Full Receipt resending is queued.')
      })
      .catch((error) => {
        setError(parseErrorMessage(error))
      })
  }

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/payment/orders">Orders</Link>
          </li>
          {order ? (
            <li className="breadcrumb-item active">{order.id}</li>
          ) : null}
        </ol>
      </nav>
      <div className="container-fluid">
        {success ? <p className="alert alert-success">{success}</p> : null}
        {error ? <p className="alert alert-danger">{error}</p> : null}

        {order ? (
          <div>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>ID</th>
                  <td>{order.id}</td>
                </tr>
                <tr>
                  <th>Date</th>
                  <td>
                    <Moment format="YYYY-MM-DD HH:mm:ss">{order.date}</Moment>
                  </td>
                </tr>
                <tr>
                  <th>Account</th>
                  <td>
                    <Link to={'/payment/accounts/' + order.account.id}>
                      {order.account.email}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <th>Name</th>
                  <td>{order.name}</td>
                </tr>
                <tr>
                  <th>Amount</th>
                  <td>{order.amount}</td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>
                    {order.status}
                    {order.status === 'wait' ? (
                      <form
                        method="post"
                        onSubmit={payExternalHandleSubmit}
                        className="form-inline"
                      >
                        <div className="form-group">
                          <input
                            name="date"
                            value={payExternalFormData.date}
                            onChange={payExternalHandleChange}
                            type="text"
                            size="30"
                            className={
                              'form-control' +
                              (payExternalErrors.date ? ' is-invalid' : '')
                            }
                            required
                          />
                          <div className="invalid-feedback">
                            {payExternalErrors.date}
                          </div>
                        </div>
                        <div className="form-group">
                          <input
                            name="service"
                            value={payExternalFormData.service}
                            onChange={payExternalHandleChange}
                            type="text"
                            size="30"
                            placeholder="Service"
                            className={
                              'form-control' +
                              (payExternalErrors.service ? ' is-invalid' : '')
                            }
                            required
                          />
                          <div className="invalid-feedback">
                            {payExternalErrors.service}
                          </div>
                        </div>
                        <div className="form-group">
                          <input
                            name="transaction"
                            value={payExternalFormData.transaction}
                            onChange={payExternalHandleChange}
                            type="text"
                            size="30"
                            placeholder="Transaction"
                            className={
                              'form-control' +
                              (payExternalErrors.transaction
                                ? ' is-invalid'
                                : '')
                            }
                            required
                          />
                          <div className="invalid-feedback">
                            {payExternalErrors.transaction}
                          </div>
                        </div>
                        <button type="submit" className="btn btn-primary">
                          Pay External
                        </button>
                      </form>
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </table>
            {payments ? (
              <div>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Service</th>
                      <th>Transaction</th>
                      <th colSpan="6">Receipts</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments.map((payment) => (
                      <tr key={payment.id}>
                        <td>{payment.id}</td>
                        <td>
                          <Moment format="YYYY-MM-DD HH:mm:ss">
                            {payment.date}
                          </Moment>
                        </td>
                        <td>{payment.amount}</td>
                        <td>{payment.service}</td>
                        <td>{payment.transaction_id}</td>
                        <td>
                          {payment.pre_receipt ? (
                            <a
                              href={
                                'https://receipts.ru/' + payment.pre_receipt
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              {payment.pre_receipt.slice(0, 6) + '...'}
                            </a>
                          ) : null}
                        </td>
                        <td>
                          {payment.pre_receipt_status}
                          {payment.pre_receipt &&
                          payment.pre_receipt_status !== 'sent' ? (
                            <button
                              className="btn btn-sm btn-success"
                              onClick={() => markPreSent(payment.id)}
                            >
                              Mark
                            </button>
                          ) : null}
                        </td>
                        <td>
                          {payment.post_receipt ? (
                            <a
                              href={
                                'https://receipts.ru/' + payment.post_receipt
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              {payment.post_receipt.slice(0, 6) + '...'}
                            </a>
                          ) : null}
                        </td>
                        <td>
                          {payment.post_receipt_status}
                          {payment.post_receipt &&
                          payment.post_receipt_status !== 'sent' ? (
                            <button
                              className="btn btn-sm btn-success"
                              onClick={() => markPostSent(payment.id)}
                            >
                              Mark
                            </button>
                          ) : null}
                          {payment.pre_receipt_status === 'sent' &&
                          !payment.post_receipt ? (
                            <button
                              className="btn btn-sm btn-success"
                              onClick={() => postReceipt(payment.id)}
                            >
                              Send
                            </button>
                          ) : null}
                        </td>
                        <td>
                          {payment.full_receipt ? (
                            <a
                              href={
                                'https://receipts.ru/' + payment.full_receipt
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              {payment.full_receipt.slice(0, 6) + '...'}
                            </a>
                          ) : null}
                        </td>
                        <td>
                          {payment.full_receipt_status}
                          {payment.full_receipt &&
                          payment.full_receipt_status !== 'sent' ? (
                            <button
                              className="btn btn-sm btn-success"
                              onClick={() => markFullSent(payment.id)}
                            >
                              Mark
                            </button>
                          ) : null}
                          {!payment.pre_receipt && !payment.full_receipt ? (
                            <button
                              className="btn btn-sm btn-success"
                              onClick={() => fullReceipt(payment.id)}
                            >
                              Send
                            </button>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default PaymentOrdersShow

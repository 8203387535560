import React, { useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import AuthContext from '../../../../context/auth/AuthContext'
import Moment from 'react-moment'
import useSWR from 'swr'
import fetchApi from '../../../../fetchApi'
import AlertError from '../../../../components/Alert/AlertError'
import Loading from '../../../../components/Loading'

export default function CorporateCompaniesShow() {
  const [token] = useContext(AuthContext)
  const params = useParams()

  const {
    data: company,
    isLoading: companyIsLoading,
    error: companyError,
  } = useSWR(
    { uri: '/edge/admin/corporate/companies/' + params.id, token },
    fetchApi,
  )

  const {
    data: employees,
    isLoading: employeesIsLoading,
    error: employeesError,
  } = useSWR(
    {
      uri: '/edge/admin/corporate/companies/' + params.id + '/employees',
      token,
    },
    fetchApi,
  )

  const {
    data: subscription,
    isLoading: subscriptionIsLoading,
    error: subscriptionError,
  } = useSWR(
    {
      uri: '/edge/admin/corporate/companies/' + params.id + '/subscription',
      token,
    },
    fetchApi,
  )

  const {
    data: orders,
    isLoading: ordersIsLoading,
    error: ordersError,
  } = useSWR(
    { uri: '/edge/admin/corporate/companies/' + params.id + '/orders', token },
    fetchApi,
  )

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/corporate/companies">Companies</Link>
          </li>
          {company ? (
            <li className="breadcrumb-item active">{company.requisite.name}</li>
          ) : null}
        </ol>
      </nav>
      <div className="container-fluid">
        {companyError ? <AlertError message={companyError.status} /> : null}
        {companyIsLoading ? <Loading /> : null}
        {company ? (
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th>Наименование</th>
                <td>{company.requisite.name}</td>
                <th>Банк</th>
                <td>
                  {company.requisite.account.bik},{' '}
                  {company.requisite.account.bank}
                </td>
              </tr>
              <tr>
                <th>ИНН</th>
                <td>{company.inn}</td>
                <th>К/с</th>
                <td>{company.requisite.account.correspondent}</td>
              </tr>
              <tr>
                <th>КПП</th>
                <td>{company.kpp}</td>
                <th>Р/с</th>
                <td>{company.requisite.account.settlement}</td>
              </tr>
              <tr>
                <th>Юридический адрес</th>
                <td colSpan="3">{company.requisite.address}</td>
              </tr>
              <tr>
                <th>Почтовый адрес</th>
                <td colSpan="3">{company.postal}</td>
              </tr>
            </tbody>
          </table>
        ) : null}

        <h3>Сотрудники</h3>
        {employeesError ? <AlertError message={employeesError.status} /> : null}
        {employeesIsLoading ? <Loading /> : null}
        {employees ? (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Сотрудник</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {employees.map((employee) => (
                <tr key={employee.id}>
                  <td>{employee.name}</td>
                  <td>{employee.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}

        <h3>Подписка</h3>
        {subscriptionError ? (
          <AlertError message={subscriptionError.status} />
        ) : null}
        {subscriptionIsLoading ? <Loading /> : null}
        {subscription ? (
          <div>
            {subscription.contract ? (
              <p>
                Договор № {subscription.contract.number} от{' '}
                {subscription.contract.date}
              </p>
            ) : null}
            <div>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Сотрудник</th>
                    <th>Подписан</th>
                  </tr>
                </thead>
                <tbody>
                  {employees ? (
                    <>
                      {employees.map((employee) => (
                        <tr key={'subscription-' + employee.id}>
                          <td>{employee.name}</td>
                          <td>
                            {subscription.subscribers.filter(
                              (item) => item.id === employee.id,
                            ).length > 0
                              ? 'Подписан'
                              : ''}
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={2}>...</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th colSpan="2">
                      Баланс <b>{subscription.balance} руб</b>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {subscription.invoices.map((invoice) => (
                    <tr key={invoice.id}>
                      <td>
                        <a href={invoice.url} target="_blank" rel="noreferrer">
                          Счёт № {invoice.number} от {invoice.date}
                        </a>
                      </td>
                      <td>{invoice.amount} руб</td>
                      <td>
                        {invoice.paid ? (
                          <>
                            Оплачен{' '}
                            <Moment format="YYYY-MM-DD HH:mm">
                              {invoice.paid}
                            </Moment>
                          </>
                        ) : (
                          'Ожидает оплаты'
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {subscription.withdraws.length ? (
                <>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th colSpan="2">Сотрудник</th>
                        <th>Списано</th>
                        <th>До</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subscription.withdraws.map((withdraw) => (
                        <tr key={withdraw.employee + '-' + withdraw.date}>
                          <td>{withdraw.employee}</td>
                          <td>{withdraw.amount} руб</td>
                          <td>
                            <Moment format="YYYY-MM-DD HH:mm">
                              {withdraw.date}
                            </Moment>
                          </td>
                          <td>
                            <Moment format="YYYY-MM-DD HH:mm">
                              {withdraw.expires}
                            </Moment>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : null}
              {subscription.acts.length ? (
                <>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Акт</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {subscription.acts.map((act) => (
                        <tr key={act.id}>
                          <td>
                            <a href={act.url} target="_blank" rel="noreferrer">
                              Акт № {act.number} от {act.date}
                            </a>
                          </td>
                          <td>{act.amount} руб</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : null}
            </div>
          </div>
        ) : null}

        <h3>Заказы</h3>
        {ordersError ? <AlertError message={ordersError.status} /> : null}
        {ordersIsLoading ? <Loading /> : null}
        {orders ? (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Счёт</th>
                <th>Продукт</th>
                <th>Сотрудники</th>
                <th>Акт</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order.id}>
                  <td>
                    <a
                      href={order.invoice.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Счёт № {order.invoice.number} от {order.invoice.date}
                    </a>
                    <br />
                    {order.invoice.paid ? (
                      <>
                        Оплачен{' '}
                        <Moment format="YYYY-MM-DD HH:mm">
                          {order.invoice.paid}
                        </Moment>
                      </>
                    ) : (
                      'Ожидает оплаты'
                    )}
                  </td>
                  <td>{order.product.title}</td>
                  <td style={{ padding: 0 }}>
                    <table
                      className="table"
                      style={{
                        margin: 0,
                        padding: 0,
                        width: '100%',
                      }}
                      cellSpacing="0"
                    >
                      <tbody>
                        {order.members.map((member) => (
                          <tr key={'order-member-' + member.name}>
                            <td style={{ border: 'none' }}>{member.name}</td>
                            <td
                              style={{
                                border: 'none',
                                textAlign: 'right',
                              }}
                            >
                              {member.price} руб
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                  <td>
                    {order.act ? (
                      <a href={order.act.url} target="_blank" rel="noreferrer">
                        Акт № {order.act.number} от {order.act.date}
                      </a>
                    ) : order.invoice.paid ? (
                      <span>Будет после завершения</span>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </div>
    </div>
  )
}

import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../context/auth/AuthContext'
import handleResponse from '../../handleResponse'
import parseErrorMessage from '../../parseErrorMessage'
import Pagination from '../../components/Pagination'
import Moment from 'react-moment'
import usePage from '../../usePage'
import EditForm from '../../components/Comments/EditForm'
import useSWR from 'swr'
import fetchApi from '../../fetchApi'
import Loading from '../../components/Loading'
import AlertError from '../../components/Alert/AlertError'

function CommentsIndex() {
  const [token] = useContext(AuthContext)
  const page = usePage()

  const {
    data: comments,
    isLoading,
    error: commentsError,
    mutate,
  } = useSWR({ uri: '/edge/admin/comments?page=' + page, token }, fetchApi)

  const [edit, setEdit] = useState(null)
  const [error, setError] = useState(null)

  const activate = (id, event) => {
    event.preventDefault()
    fetch('/api/edge/admin/comments/' + id + '/activate', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then(() => mutate())
      .catch((error) => {
        setError(parseErrorMessage(error))
      })
  }

  const block = (id, event) => {
    event.preventDefault()
    fetch('/api/edge/admin/comments/' + id + '/block', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then(() => mutate())
      .catch((error) => {
        setError(parseErrorMessage(error))
      })
  }

  const remove = (id, event) => {
    event.preventDefault()
    if (!confirm('Delete?')) {
      return
    }
    fetch('/api/edge/admin/comments/' + id, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then(() => mutate())
      .catch((error) => {
        setError(parseErrorMessage(error))
      })
  }

  const success = async () => {
    setEdit(null)
    await mutate()
  }

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Comments</li>
        </ol>
      </nav>
      <div className="container-fluid">
        <AlertError message={error} />
        {commentsError ? <AlertError message={commentsError.status} /> : null}
        {isLoading ? <Loading /> : null}
        {comments ? (
          <div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Date</th>
                  <th />
                  <th>Text</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {comments.items.map((comment) => (
                  <tr key={comment.id}>
                    <td>
                      <Moment format="YYYY-MM-DD HH:mm:ss">
                        {comment.date}
                      </Moment>
                      <br />
                      {comment.ip}
                    </td>
                    <td>
                      <img src={comment.author.avatar} alt="" />
                    </td>
                    <td>
                      <p>
                        <b>{comment.author.name}</b>
                        <br />
                        {comment.author.email}
                      </p>
                      <div
                        style={{ maxWidth: '700px' }}
                        dangerouslySetInnerHTML={{ __html: comment.text }}
                      />
                      {edit === comment.id ? (
                        <div>
                          <EditForm
                            id={comment.id}
                            onSuccess={success}
                            onCancel={() => setEdit(null)}
                          />
                        </div>
                      ) : null}
                      <p className="controls" style={{ margin: 0 }}>
                        <a
                          href={comment.url}
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-sm btn-outline-primary"
                        >
                          Show on Page
                        </a>
                        {comment.status !== 'active' ? (
                          <button
                            className="btn btn-sm btn-outline-success"
                            onClick={(e) => activate(comment.id, e)}
                          >
                            Activate
                          </button>
                        ) : null}
                        {comment.status !== 'blocked' ? (
                          <button
                            className="btn btn-sm btn-outline-primary"
                            onClick={(e) => block(comment.id, e)}
                          >
                            Block
                          </button>
                        ) : null}
                        <button
                          className="btn btn-sm btn-outline-primary"
                          onClick={() => setEdit(comment.id)}
                        >
                          Edit
                        </button>
                        <button
                          className="btn btn-sm btn-outline-danger"
                          onClick={(e) => remove(comment.id, e)}
                        >
                          Delete
                        </button>
                      </p>
                    </td>
                    <td>{comment.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              page={page}
              totalRows={comments.pagination.total}
              perPage={comments.pagination.per_page}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default CommentsIndex

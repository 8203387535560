import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../../context/auth/AuthContext'
import Pagination from '../../../components/Pagination'
import Moment from 'react-moment'
import usePage from '../../../usePage'
import styles from './Index.module.scss'
import useSWR from 'swr'
import fetchApi from '../../../fetchApi'
import AlertError from '../../../components/Alert/AlertError'
import Loading from '../../../components/Loading'

export default function PaymentOrdersIndex() {
  const [token] = useContext(AuthContext)
  const page = usePage()

  const {
    data: orders,
    isLoading,
    error,
  } = useSWR(
    { uri: '/edge/admin/payment/orders?page=' + page, token },
    fetchApi,
  )

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Orders</li>
        </ol>
      </nav>
      <div className="container-fluid">
        {error ? <AlertError message={error.status} /> : null}
        {isLoading ? <Loading /> : null}

        {orders ? (
          <div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Order</th>
                  <th>Paid</th>
                  <th />
                  <th>Account</th>
                  <th style={{ textAlign: 'center' }} title="Other Orders">
                    O
                  </th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {orders.items.map((order) => (
                  <tr
                    key={order.id}
                    className={
                      order.without_post_receipts_count
                        ? styles.wpr
                        : order.is_first
                          ? styles.first
                          : ''
                    }
                  >
                    <td>
                      <Link to={'/payment/orders/' + order.id}>
                        {order.id.split('-')[0]}...
                      </Link>
                    </td>
                    <td>
                      <Moment format="YYYY-MM-DD HH:mm:ss">{order.date}</Moment>
                    </td>
                    <td>
                      {order.pay_date ? (
                        <Moment format="YYYY-MM-DD HH:mm:ss">
                          {order.pay_date}
                        </Moment>
                      ) : null}
                    </td>
                    <td style={{ textAlign: 'center' }}>{order.amount}</td>
                    <td>
                      <Link to={'/payment/accounts/' + order.account.id}>
                        {order.account.email}
                      </Link>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {order.account_other_orders_count ? (
                        <span>{order.account_other_orders_count}</span>
                      ) : null}
                    </td>
                    <td>{order.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              page={page}
              totalRows={orders.pagination.total}
              perPage={orders.pagination.per_page}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

import React, { useContext, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import AuthContext from '../../../../../context/auth/AuthContext'
import handleResponse from '../../../../../handleResponse'
import parseErrorMessage from '../../../../../parseErrorMessage'
import Moment from 'react-moment'
import useSWR from 'swr'
import fetchApi from '../../../../../fetchApi'
import AlertError from '../../../../../components/Alert/AlertError'
import Loading from '../../../../../components/Loading'

export default function EduSeriesEpisodesIndex() {
  const params = useParams()

  const [token] = useContext(AuthContext)

  const {
    data: series,
    isLoading: seriesIsLoading,
    error: seriesError,
  } = useSWR(
    { uri: '/edge/admin/edu/series/' + params.series, token },
    fetchApi,
  )

  const {
    data: episodes,
    isLoading: episodesIsLoading,
    error: episodesError,
    mutate,
  } = useSWR(
    { uri: '/edge/admin/edu/series/' + params.series + '/episodes', token },
    fetchApi,
  )

  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)

  const remove = (id, event) => {
    event.preventDefault()
    if (!confirm('Remove?')) {
      return
    }
    fetch('/api/edge/admin/edu/series/' + params.series + '/episodes/' + id, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then(() => {
        mutate().then(() => setSuccess('Success!'))
      })
      .catch((error) => {
        setError(parseErrorMessage(error))
      })
  }

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/edu/series">Series</Link>
          </li>
          {series ? (
            <li className="breadcrumb-item">
              <Link to={'/edu/series/' + series.id}>
                {series.content.title}
              </Link>
            </li>
          ) : null}
          <li className="breadcrumb-item active">Episodes</li>
        </ol>
      </nav>
      <div className="container-fluid">
        {success ? <p className="alert alert-success">{success}</p> : null}
        {error ? <p className="alert alert-danger">{error}</p> : null}

        {seriesError ? <AlertError message={seriesError.status} /> : null}
        {seriesIsLoading ? <Loading /> : null}
        {series ? (
          <div>
            <p className="controls">
              <Link
                to={'/edu/series/' + series.id + '/episodes/create'}
                className="btn btn-primary"
              >
                Create
              </Link>
            </p>
          </div>
        ) : null}

        {episodesError ? <AlertError message={episodesError.status} /> : null}
        {episodesIsLoading ? <Loading /> : null}
        {episodes ? (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th>Slug</th>
                <th>Authors</th>
                <th>Sort</th>
                <th>Publish</th>
                <th style={{ textAlign: 'center' }}>Free</th>
                <th style={{ textAlign: 'center' }}>Demo</th>
                <th style={{ textAlign: 'center' }}>Status</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {episodes.map((episode) => (
                <tr key={episode.id}>
                  <td>
                    <Link
                      to={
                        '/edu/series/' + series.id + '/episodes/' + episode.id
                      }
                    >
                      {episode.title}
                    </Link>
                  </td>
                  <td>{episode.slug}</td>
                  <td>
                    {episode.authors.map((author) => (
                      <>
                        {author ? (
                          <span key={author.id} className="badge badge-primary">
                            {author.name}
                          </span>
                        ) : null}
                      </>
                    ))}
                  </td>
                  <td>{episode.sort}</td>
                  <td>
                    {episode.publish_date ? (
                      <Moment format="YYYY-MM-DD HH:mm:ss">
                        {episode.publish_date}
                      </Moment>
                    ) : null}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {episode.free ? (
                      <span className="badge badge-success">Free</span>
                    ) : null}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {episode.demo ? (
                      <span className="badge badge-success">Demo</span>
                    ) : null}
                  </td>
                  <td style={{ textAlign: 'center' }}>{episode.status}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={(e) => remove(episode.id, e)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </div>
    </div>
  )
}

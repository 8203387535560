import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../../context/auth/AuthContext'
import Moment from 'react-moment'
import fetchApi from '../../../fetchApi'
import AlertError from '../../../components/Alert/AlertError'
import Loading from '../../../components/Loading'
import useSWR from 'swr'

export default function QnAQuestionsIndex() {
  const [token] = useContext(AuthContext)

  const {
    data: questions,
    isLoading,
    error,
  } = useSWR({ uri: '/edge/admin/qna/questions', token }, fetchApi)

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Questions</li>
        </ol>
      </nav>
      <div className="container-fluid">
        <p className="controls">
          <Link to="/qna/questions/create" className="btn btn-primary">
            Create
          </Link>
        </p>

        {error ? <AlertError message={error.status} /> : null}
        {isLoading ? <Loading /> : null}

        {questions ? (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Title</th>
                <th>Answers</th>
                <th>Slug</th>
                <th>Publish</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {questions.map((item) => (
                <tr key={item.id}>
                  <td>
                    <Link to={'/qna/questions/' + item.id}>{item.title}</Link>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {item.answers_count > 0 ? item.answers_count : ''}
                  </td>
                  <td>{item.slug}</td>
                  <td>
                    {item.publish_date ? (
                      <Moment format="YYYY-MM-DD HH:mm:ss">
                        {item.publish_date}
                      </Moment>
                    ) : null}
                  </td>
                  <td>{item.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </div>
    </div>
  )
}

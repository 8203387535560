import React, { useContext, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import AuthContext from '../../../../context/auth/AuthContext'
import handleResponse from '../../../../handleResponse'
import parseErrorMessage from '../../../../parseErrorMessage'
import Moment from 'react-moment'
import useSWR from 'swr'
import fetchApi from '../../../../fetchApi'
import AlertError from '../../../../components/Alert/AlertError'
import Loading from '../../../../components/Loading'

export default function BlogPostsPostShow() {
  const [token] = useContext(AuthContext)
  const params = useParams()
  const navigate = useNavigate()

  const {
    data: post,
    postIsLoading,
    error: postError,
    mutate,
  } = useSWR({ uri: '/edge/admin/blog/posts/' + params.post, token }, fetchApi)

  const {
    data: products,
    productsIsLoading,
    error: productsError,
  } = useSWR(
    { uri: '/edge/admin/blog/posts/' + params.post + '/products', token },
    fetchApi,
  )

  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)

  const remove = (event) => {
    event.preventDefault()
    if (!confirm('Remove?')) {
      return
    }

    fetch('/api/edge/admin/blog/posts/' + post.id, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then(() => {
        navigate('/blog/posts')
      })
      .catch((error) => {
        setError(parseErrorMessage(error))
      })
  }

  const draft = (event) => {
    event.preventDefault()
    if (!confirm('Draft?')) {
      return
    }
    fetch('/api/edge/admin/blog/posts/' + post.id + '/draft', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(handleResponse)
      .then(() => {
        mutate().then(() => setSuccess('Success!'))
      })
      .catch((error) => {
        setError(parseErrorMessage(error))
      })
  }

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/blog/posts">Posts</Link>
          </li>
          {post ? (
            <li className="breadcrumb-item active">{post.content.title}</li>
          ) : null}
        </ol>
      </nav>
      <div className="container-fluid">
        {success ? <p className="alert alert-success">{success}</p> : null}
        {error ? <p className="alert alert-danger">{error}</p> : null}

        {postError ? <AlertError message={postError.status} /> : null}
        {postIsLoading ? <Loading /> : null}

        {post ? (
          <div>
            <p className="controls">
              <Link
                to={'/blog/posts/' + post.id + '/edit'}
                className="btn btn-primary"
              >
                Edit
              </Link>
              {!post.active ? (
                <Link
                  to={'/blog/posts/' + post.id + '/publish'}
                  className="btn btn-primary"
                >
                  Publish
                </Link>
              ) : null}
              {post.active ? (
                <button className="btn btn-primary" onClick={draft}>
                  Draft
                </button>
              ) : null}
              {post.active ? (
                <Link
                  to={'/blog/posts/' + post.id + '/change-date'}
                  className="btn btn-primary"
                >
                  Change Date
                </Link>
              ) : null}
              <button className="btn btn-danger" onClick={remove}>
                Delete
              </button>
            </p>

            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>Date</th>
                  <td>
                    <Moment format="YYYY-MM-DD HH:mm:ss">{post.date}</Moment>
                  </td>
                </tr>
                <tr>
                  <th>Slug</th>
                  <td>{post.slug}</td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>{post.status}</td>
                </tr>
                <tr>
                  <th>Publish Date</th>
                  <td>
                    {post.publish_date ? (
                      <Moment format="YYYY-MM-DD HH:mm:ss">
                        {post.publish_date}
                      </Moment>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <th>Title</th>
                  <td>{post.content.title}</td>
                </tr>
                <tr>
                  <th>Thumbnail</th>
                  <td>
                    {post.content.thumbnail ? (
                      <img
                        src={post.content.thumbnail}
                        style={{ maxWidth: '320px' }}
                        alt=""
                      />
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <th>Photo</th>
                  <td>
                    {post.content.photo ? (
                      <img
                        src={post.content.photo}
                        style={{ maxWidth: '320px' }}
                        alt=""
                      />
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <th>Meta Title</th>
                  <td>{post.meta.title}</td>
                </tr>
                <tr>
                  <th>Meta Description</th>
                  <td>{post.meta.description}</td>
                </tr>
                <tr>
                  <th>Products</th>
                  <td>
                    {productsError ? (
                      <AlertError message={productsError.status} />
                    ) : null}
                    {productsIsLoading ? <Loading /> : null}
                    {products
                      ? products.map((product) => (
                          <div key={product.id}>{product.title}</div>
                        ))
                      : null}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="card mb-3">
              <div className="card-body">{post.content.short}</div>
            </div>
            <div className="card">
              <div className="card-body">{post.content.text}</div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

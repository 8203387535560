import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../../context/auth/AuthContext'
import Pagination from '../../../components/Pagination'
import Moment from 'react-moment'
import usePage from '../../../usePage'
import useSWR from 'swr'
import fetchApi from '../../../fetchApi'
import AlertError from '../../../components/Alert/AlertError'
import Loading from '../../../components/Loading'

function PaymentPaymentsIndex() {
  const [token] = useContext(AuthContext)
  const page = usePage()

  const {
    data: payments,
    isLoading,
    error,
  } = useSWR(
    { uri: '/edge/admin/payment/payments?page=' + page, token },
    fetchApi,
  )

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Payments</li>
        </ol>
      </nav>
      <div className="container-fluid">
        {error ? <AlertError message={error.status} /> : null}
        {isLoading ? <Loading /> : null}

        {payments ? (
          <div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Date</th>
                  <th />
                  <th>Total</th>
                  <th>Account</th>
                  <th>Service</th>
                  <th>Transaction</th>
                  <th colSpan="6">Receipts</th>
                </tr>
              </thead>
              <tbody>
                {payments.items.map((item) => (
                  <tr key={item.payment.id}>
                    <td>
                      {item.subscription ? (
                        <Link
                          to={'/payment/subscriptions/' + item.subscription.id}
                        >
                          {item.payment.id.split('-')[0]}...
                        </Link>
                      ) : null}
                      {item.order ? (
                        <Link to={'/payment/orders/' + item.order.id}>
                          {item.payment.id.split('-')[0]}...
                        </Link>
                      ) : null}
                    </td>
                    <td>
                      <Moment format="YYYY-MM-DD HH:mm:ss">
                        {item.payment.date}
                      </Moment>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {item.payment.amount}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {item.subscription
                        ? item.subscription.payments_count
                        : null}
                      {item.order ? item.order.payments_count : null}
                    </td>
                    <td>
                      {item.subscription ? (
                        <Link
                          to={
                            '/payment/accounts/' + item.subscription.account.id
                          }
                        >
                          {item.subscription.account.email}
                        </Link>
                      ) : null}
                      {item.order ? (
                        <Link to={'/payment/accounts/' + item.order.account.id}>
                          {item.order.account.email}
                        </Link>
                      ) : null}
                    </td>
                    <td>{item.payment.service}</td>
                    <td>{item.payment.transaction_id}</td>
                    <td>
                      {item.payment.pre_receipt ? (
                        <a
                          href={
                            'https://receipts.ru/' + item.payment.pre_receipt
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.payment.pre_receipt.slice(0, 6) + '...'}
                        </a>
                      ) : null}
                    </td>
                    <td>{item.payment.pre_receipt_status}</td>
                    <td>
                      {item.payment.post_receipt ? (
                        <a
                          href={
                            'https://receipts.ru/' + item.payment.post_receipt
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.payment.post_receipt.slice(0, 6) + '...'}
                        </a>
                      ) : null}
                    </td>
                    <td>{item.payment.post_receipt_status}</td>
                    <td>
                      {item.payment.full_receipt ? (
                        <a
                          href={
                            'https://receipts.ru/' + item.payment.full_receipt
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.payment.full_receipt.slice(0, 6) + '...'}
                        </a>
                      ) : null}
                    </td>
                    <td>{item.payment.full_receipt_status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              page={page}
              totalRows={payments.pagination.total}
              perPage={payments.pagination.per_page}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default PaymentPaymentsIndex

import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../../context/auth/AuthContext'
import Moment from 'react-moment'
import AlertError from '../../../components/Alert/AlertError'
import Loading from '../../../components/Loading'
import fetchApi from '../../../fetchApi'
import useSWR from 'swr'

function ProductsIndex() {
  const [token] = useContext(AuthContext)

  const {
    data: products,
    isLoading,
    error,
  } = useSWR({ uri: '/edge/admin/product/products', token }, fetchApi)

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Products</li>
        </ol>
      </nav>
      <div className="container-fluid">
        <p className="controls">
          <Link to="/product/products/create" className="btn btn-primary">
            Create
          </Link>
        </p>

        {error ? <AlertError message={error.status} /> : null}
        {isLoading ? <Loading /> : null}
        {products ? (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Date</th>
                <th>Title</th>
                <th>Slug</th>
                <th>Price</th>
                <th>Active</th>
                <th>Closed</th>
              </tr>
            </thead>
            <tbody>
              {products.map((item) => (
                <tr key={item.id}>
                  <td>
                    <Moment format="YYYY-MM-DD HH:mm:ss">{item.date}</Moment>
                  </td>
                  <td>
                    <Link to={'/product/products/' + item.id}>
                      {item.title}
                    </Link>
                  </td>
                  <td>{item.slug}</td>
                  <td>{item.price}</td>
                  <td>{item.active ? 'Yes' : 'No'}</td>
                  <td>
                    {item.closeDate ? (
                      <Moment format="YYYY-MM-DD HH:mm:ss">
                        {item.closeDate}
                      </Moment>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </div>
    </div>
  )
}

export default ProductsIndex

import React, { useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import AuthContext from '../../../../context/auth/AuthContext'
import Moment from 'react-moment'
import useSWR from 'swr'
import fetchApi from '../../../../fetchApi'
import AlertError from '../../../../components/Alert/AlertError'
import Loading from '../../../../components/Loading'

export default function PaymentAccountsShow() {
  const params = useParams()
  const [token] = useContext(AuthContext)

  const {
    data: account,
    isLoading: accountIsLoading,
    error: accountError,
  } = useSWR(
    { uri: '/edge/admin/payment/accounts/' + params.id, token },
    fetchApi,
  )

  const {
    data: subscriptions,
    isLoading: subscriptionsIsLoading,
    error: subscriptionsError,
  } = useSWR(
    {
      uri: '/edge/admin/payment/accounts/' + params.id + '/subscriptions',
      token,
    },
    fetchApi,
  )

  const {
    data: orders,
    isLoading: ordersIsLoading,
    error: ordersError,
  } = useSWR(
    {
      uri: '/edge/admin/payment/accounts/' + params.id + '/orders',
      token,
    },
    fetchApi,
  )

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">Accounts</li>
          {account ? (
            <li className="breadcrumb-item active">{account.email}</li>
          ) : null}
        </ol>
      </nav>
      <div className="container-fluid">
        {accountError ? <AlertError message={accountError.status} /> : null}
        {accountIsLoading ? <Loading /> : null}
        {account ? (
          <div>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>Email</th>
                  <td>{account.email}</td>
                </tr>
                <tr>
                  <th>Active</th>
                  <td>{account.active ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <th>ID</th>
                  <td>{account.id}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : null}

        {subscriptionsError ? (
          <AlertError message={subscriptionsError.status} />
        ) : null}
        {subscriptionsIsLoading ? <Loading /> : null}
        {subscriptions ? (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Subscription</th>
                <th>Order</th>
                <th>Activate</th>
                <th>Pays</th>
                <th title="Other Subscriptions">Subs</th>
                <th title="Without Post Recipes">WP</th>
                <th>External ID</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {subscriptions.map((subscription) => (
                <tr key={subscription.id}>
                  <td>
                    <Link to={'/payment/subscriptions/' + subscription.id}>
                      {subscription.id.split('-')[0]}...
                    </Link>
                  </td>
                  <td>
                    <Moment format="YYYY-MM-DD HH:mm:ss">
                      {subscription.date}
                    </Moment>
                  </td>
                  <td>
                    {subscription.activate_date ? (
                      <Moment format="YYYY-MM-DD HH:mm:ss">
                        {subscription.activate_date}
                      </Moment>
                    ) : null}
                  </td>
                  <td>
                    {subscription.payments_count > 0
                      ? subscription.payments_count
                      : ''}
                  </td>
                  <td>
                    {subscription.account_other_subscriptions_count ? (
                      <span>
                        {subscription.account_other_subscriptions_count}
                      </span>
                    ) : null}
                  </td>
                  <td>
                    {subscription.without_post_receipts_count ? (
                      <span>{subscription.without_post_receipts_count}</span>
                    ) : null}
                  </td>
                  <td>
                    {subscription.external_id
                      ? subscription.external_id.slice(1, 10) + '...'
                      : ''}
                  </td>
                  <td>{subscription.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}

        {ordersError ? <AlertError message={ordersError.status} /> : null}
        {ordersIsLoading ? <Loading /> : null}
        {orders ? (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Order</th>
                <th>Order</th>
                <th>Paid</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order.id}>
                  <td>
                    <Link to={'/payment/orders/' + order.id}>
                      {order.id.split('-')[0]}...
                    </Link>
                  </td>
                  <td>
                    <Moment format="YYYY-MM-DD HH:mm:ss">{order.date}</Moment>
                  </td>
                  <td>
                    {order.pay_date ? (
                      <Moment format="YYYY-MM-DD HH:mm:ss">
                        {order.pay_date}
                      </Moment>
                    ) : null}
                  </td>
                  <td style={{ textAlign: 'center' }}>{order.amount}</td>
                  <td>{order.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </div>
    </div>
  )
}

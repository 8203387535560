import AuthContext from '../../../context/auth/AuthContext'
import React, { useContext } from 'react'
import styles from './Orders.module.scss'
import useSWR from 'swr'
import fetchApi from '../../../fetchApi'
import Loading from '../../Loading'
import AlertError from '../../Alert/AlertError'

export default function StatPaymentOrders() {
  const [token] = useContext(AuthContext)

  const { data, isLoading, error } = useSWR(
    { uri: '/edge/admin/payment/stat/orders', token },
    fetchApi,
  )

  return (
    <div className="card">
      <div className="card-header">Покупки</div>
      <div className={styles.body}>
        {isLoading ? <Loading /> : null}
        {error ? <AlertError message={error.status} /> : null}
        {data ? (
          <div className={styles.overflow}>
            <table className={'table table-bordered m-0 ' + styles.daily}>
              <thead>
                <tr>
                  {data.map((item) => (
                    <th key={'orders-day-' + item.day} title={item.day}>
                      {item.day.split('-').slice(-1).join()}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {data.map((item) => (
                    <td key={'orders-total-' + item.day} title="Клиентов">
                      {item.total > 0 ? <span>{item.total}</span> : null}
                    </td>
                  ))}
                </tr>
                <tr>
                  {data.map((item) => (
                    <td key={'orders-paid-' + item.day} title="Всего">
                      {item.paid > 0 ? <span>{item.paid}</span> : null}
                    </td>
                  ))}
                </tr>
                <tr>
                  {data.map((item) => (
                    <td key={'orders-payments-' + item.day} title="Сегодня">
                      {item.payments > 0 ? <span>{item.payments}</span> : null}
                    </td>
                  ))}
                </tr>
                <tr>
                  {data.map((item) => (
                    <td key={'orders-sum-' + item.day} title="Сумма">
                      {item.sum > 0 ? (
                        <span>{(item.sum / 1000).toFixed(1)}</span>
                      ) : null}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    </div>
  )
}

import AuthContext from '../../../context/auth/AuthContext'
import React, { useContext } from 'react'
import styles from './Users.module.scss'
import fetchApi from '../../../fetchApi'
import useSWR from 'swr'
import Loading from '../../Loading'
import AlertError from '../../Alert/AlertError'

export default function StatUsers() {
  const [token] = useContext(AuthContext)

  const { data, isLoading, error } = useSWR(
    { uri: '/edge/admin/users/stat/users', token },
    fetchApi,
  )

  return (
    <div className="card">
      <div className="card-header">Пользователи</div>
      <div className={styles.body}>
        {isLoading ? <Loading /> : null}
        {error ? <AlertError message={error.status} /> : null}
        {data ? (
          <div className={styles.overflow}>
            <table className={'table table-bordered m-0 ' + styles.daily}>
              <thead>
                <tr>
                  {data.map((item) => (
                    <th key={'users-day' + item.day} title={item.day}>
                      {item.day.split('-').slice(-1).join()}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {data.map((item) => (
                    <td key={'users-active' + item.day}>{item.active}</td>
                  ))}
                </tr>
                <tr>
                  {data.map((item) => (
                    <td key={'users-activated' + item.day}>
                      {item.activated > 0 ? (
                        <span>+{item.activated}</span>
                      ) : null}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    </div>
  )
}

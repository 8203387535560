import React, { useContext } from 'react'
import AuthContext from '../context/auth/AuthContext'
import { SWRConfig } from 'swr'

export function SWRGlobalCongig({ children }: { children: React.ReactNode }) {
  const [, setToken] = useContext(AuthContext)

  return (
    <SWRConfig
      value={{
        onError: (error) => {
          if (error.status === 401) {
            setToken(null)
          }
        },
      }}
    >
      {children}
    </SWRConfig>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function handleResponse<T>(response: Response): Promise<T> {
  if (response.ok) {
    if (response.status === 204) {
      return Promise.resolve('') as Promise<T>
    }
    const contentType = response.headers.get('content-type')
    if (contentType && contentType.includes('application/json')) {
      return response.json() as Promise<T>
    }
    return response.text() as Promise<T>
  }
  throw response
}

import AuthContext from '../../../context/auth/AuthContext'
import React, { useContext } from 'react'
import styles from './Subscriptions.module.scss'
import useSWR from 'swr'
import fetchApi from '../../../fetchApi'
import Loading from '../../Loading'
import AlertError from '../../Alert/AlertError'

export default function StatPaymentSubscriptions() {
  const [token] = useContext(AuthContext)

  const { data, isLoading, error } = useSWR(
    { uri: '/edge/admin/payment/stat/subscriptions', token },
    fetchApi,
  )

  return (
    <div className="card">
      <div className="card-header">Подписки</div>
      <div className={styles.body}>
        {isLoading ? <Loading /> : null}
        {error ? <AlertError message={error.status} /> : null}
        {data ? (
          <div className={styles.overflow}>
            <table className={'table table-bordered m-0 ' + styles.daily}>
              <thead>
                <tr>
                  {data.map((item) => (
                    <th key={'subscriptions-day' + item.day} title={item.day}>
                      {item.day.split('-').slice(-1).join()}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {data.map((item) => (
                    <td key={'subscriptions-total' + item.day} title="Клиентов">
                      {item.total > 0 ? <span>{item.total}</span> : null}
                    </td>
                  ))}
                </tr>
                <tr>
                  {data.map((item) => (
                    <td
                      key={'subscriptions-active' + item.day}
                      title="Активные"
                    >
                      {item.active > 0 ? <span>{item.active}</span> : null}
                    </td>
                  ))}
                </tr>
                <tr>
                  {data.map((item) => (
                    <td
                      key={'subscriptions-subscribed' + item.day}
                      title="Новые подписки"
                    >
                      {item.subscribed > 0 ? (
                        <span>+{item.subscribed}</span>
                      ) : null}
                    </td>
                  ))}
                </tr>
                <tr>
                  {data.map((item) => (
                    <td
                      key={'subscriptions-paid_once' + item.day}
                      title="Одноразовые"
                    >
                      {item.paid_once > 0 ? (
                        <span>+{item.paid_once}</span>
                      ) : null}
                    </td>
                  ))}
                </tr>
                <tr>
                  {data.map((item) => (
                    <td
                      key={'subscriptions-without_subscription' + item.day}
                      title="Без подписки"
                    >
                      {item.without_subscription > 0 ? (
                        <span>+{item.without_subscription}</span>
                      ) : null}
                    </td>
                  ))}
                </tr>
                <tr>
                  {data.map((item) => (
                    <td
                      key={'subscriptions-cancelled' + item.day}
                      title="Отменённые подписки"
                    >
                      {item.cancelled > 0 ? (
                        <span>-{item.cancelled}</span>
                      ) : null}
                    </td>
                  ))}
                </tr>
                <tr>
                  {data.map((item) => (
                    <td
                      key={'subscriptions-rejected' + item.day}
                      title="Отклонённые подписки"
                    >
                      {item.rejected > 0 ? <span>-{item.rejected}</span> : null}
                    </td>
                  ))}
                </tr>
                <tr>
                  {data.map((item) => (
                    <td
                      key={'subscriptions-all_payments' + item.day}
                      title="Новые/Все платежи"
                    >
                      {item.all_payments > 0 ? (
                        <span>
                          {item.new_payments}/{item.all_payments}
                        </span>
                      ) : null}
                    </td>
                  ))}
                </tr>
                <tr>
                  {data.map((item) => (
                    <td key={'subscriptions-sum' + item.day} title="Сумма">
                      {item.all_sum > 0 ? (
                        <span>{(item.all_sum / 1000).toFixed(1)}</span>
                      ) : null}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    </div>
  )
}

import handleResponse from './handleResponse'

type Headers = Record<string, string>

type Params =
  | {
      uri: string
      token: string | null
    }
  | string

export default function fetchApi<T>(params: Params): Promise<T> {
  const uri = typeof params === 'string' ? params : params.uri
  const token = typeof params === 'string' ? null : params.token

  const commonHeaders = {
    Accept: 'application/json',
  }

  const authHeaders = token ? { Authorization: token } : {}

  return fetch('/api' + uri, {
    method: 'GET',
    cache: 'no-store',
    headers: {
      ...(commonHeaders as Headers),
      ...(authHeaders as Headers),
    },
  }).then<T>(handleResponse)
}

import React from 'react'
import './index.scss'
import * as serviceWorker from './serviceWorker'
import App from './App'
import AuthProvider from './context/auth/AuthProvider'
import { createRoot } from 'react-dom/client'
import { SWRGlobalCongig } from './components/SWRGlobalCongig'

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <AuthProvider>
      <SWRGlobalCongig>
        <App />
      </SWRGlobalCongig>
    </AuthProvider>
  </React.StrictMode>,
)

serviceWorker.unregister()
